.loading {
  margin-left: '100%';
}
.borderLeft {
  border-left: 1px solid #cdd0d4 !important;
}
.borderRight {
  border-right: 1px solid #cdd0d4 !important;
}
.alertSalvar {
  padding-top: 20px;
  width: 100%;
}
