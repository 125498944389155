.customModal {
  max-width: 50%;
  width: 'fit-content';
}

@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.2);
  }
}
