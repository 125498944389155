@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #f0f2f5;
    --title: #44546A;

    --color-primary-200: #e39f6b;
    --color-primary-300: #ed9c5e;
    --color-primary-400: #f09550;
    --color-primary-500: #f4832d;
    --color-primary-600: #F56A00;
    
    --color-secondary-200: #8e9196;
    --color-secondary-300: #646e7c;
    --color-secondary-400: #5f6a78;
    --color-secondary-500: #535f70;
    --color-secondary-600: #44546A;

    --color-danger: #ff3131f2;
  }

  @media (max-width: 1024px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 87.5%;
    }
  }

  body {
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
    background: var(--background);
  }

  ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
  }

  ::-webkit-scrollbar {
    width: 15px;
    height: 20px;
  }
  
  ::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      background-color: var(--color-primary-500);
      background-clip: content-box;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  input:focus {
    box-shadow:0, 0, 0;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.table th:first-child {
  z-index: 0 !important;
}

.btn-success-custom {
  display: flex;
  align-items: center;
  justify-content: flex-center;
  /* padding: 1rem 1.5rem; */
  max-width: 250px;
  /* height: 45px; */
  background-color: var(--primary);
  color: var(--white);
  border-radius: 10px;
  transition: background-color 0.3s;
  text-transform: uppercase;
  border: 0;
}

.btn-success-custom:hover {
  background: var(--primary-hover) !important;
}
