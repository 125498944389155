.div1 {
  width: 100%;
  /* height: 100vh;
  overflow: scroll; */
  padding-right:15px;
}

.div1 table {
  border-spacing: 0;
}

.div1 th {
  position: sticky !important;
  top: 0 !important;
  font-weight: normal !important;
  }
